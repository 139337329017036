<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="showImage || contactData.photo"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ contactData.name }} {{ contactData.lastname }}
              </h4>
              <span class="card-text">{{ contactData.position }}</span> <br>
              <span class="card-text">{{ business.status_business }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                @click="editUser"
              >
                Editar
              </b-button>
              <b-button
                :id="`contact-row-${contactData.id}-delete-icon`"
                size="sm"
                variant="outline-danger"
                class=""
              >
                Eliminar
              </b-button>
              <b-popover
                  :target="`contact-row-${contactData.id}-delete-icon`"
                  variant="danger"
                  triggers="focus"
                  placement="left"
              >
                <template #title>
                  <span>Eliminar contacto: {{ contactData.name }} {{ contactData.lastname }} </span>
                </template>

                <div class="">
                  <p>
                    Al eliminar este contacto se eliminará toda su información relacionada como: COTIZACIONES,VENTAS,NOTAS,
                    TESTIMONIOS, GUÍAS.
                  </p>
                </div>

                <b-button
                    variant="outline-danger"
                    class="mr-1"
                    size="sm"
                    @click="deleteContact(contactData)"
                >
                  <span class="text-nowrap">Eliminar</span>
                </b-button>
              </b-popover>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
                variant="light-primary"
                rounded
            >
              <feather-icon
                  icon="DollarSignIcon"
                  size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ contactData.statistics.sale_month }}
              </h5>
              <small>Venta mensual</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
                variant="light-success"
                rounded
            >
              <feather-icon
                  icon="DollarSignIcon"
                  size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ contactData.statistics.sale_year }}
              </h5>
              <small>Venta anual</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon
                icon="SmartphoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Celular</span>
            </th>
            <td>
              {{ contactData.mobilePhone }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="AtSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Correo</span>
            </th>
            <td class="pb-50">
              {{ contactData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Correo Adicional</span>
            </th>
            <td class="pb-50">
              {{ contactData.emailAdditional }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PackageIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Servicio de interés</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ business.service_interest }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="UserCheckIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Vendedor Asignado</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ contactData.seller }}
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BPopover,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BPopover,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    // const { resolveUserRoleVariant } = useUsersList()
    const refInputElDesktop = ref(null)
    const photoContact = ref(null)
    const showImage = ref(null)
    const { inputImageRenderer: renderDesktop } = useInputImageRenderer(refInputElDesktop, base64 => {
      showImage.value = base64
    })

    const editUser = () => {
      emit('open-edit-sidebar')
    }
    const deleteContact = () => {
      emit('delete-contact', { id: props.contactData.id })
    }
    return {
      avatarText,
      // resolveUserRoleVariant,
      renderDesktop,
      refInputElDesktop,
      showImage,

      photoContact,
      editUser,
      deleteContact,

    }
  },
}
</script>

<style>

</style>
