<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Actividades</h3>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      responsive
      :fields="tableColumns"
      :items="activities"
      class=""
    >


      <!-- Column: Quotation status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveActivityStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(date)="data">
        <span class="text-nowrap">
          {{ data.item.date }} {{ data.item.hour }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`quotation-row-${data.item.id}-pdf-icon`"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'activity-view', params: { id: data.item.id }})"
          />
          <b-tooltip
            placement="right"
            title="Ver"
            :target="`quotation-row-${data.item.id}-pdf-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="10"
            :per-page="10"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BTooltip, BPagination, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard, BTable, BTooltip, BPagination, BRow, BCol, BBadge,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const currentPage = ref(1)
    const tableColumns = [
      { key: 'subject', label: 'Asunto', sortable: false },
      { key: 'category_name', label: 'Categoría', sortable: false },
      { key: 'date', label: 'Fecha', sortable: false },
      { key: 'status', label: 'Estado', sortable: false },
      { key: 'actions', label: 'Acciones' },
    ]

    const resolveActivityStatusVariant = status => {
      if (status === 'pendiente') return 'warning'
      if (status === 'completado') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    }

    const activityData = [
      {
        id: 169,
        subject: 'LLAMAR A DEYSI',
        date: '2022-05-31',
        hour: '23:59:00',
        description: 'WEWE',
        status: 'pendiente',
        category: '1',
        contact_id: 1332,
        business_name: 'SODIFER S.A.C',
        client: 'Deysi Coronado',
        category_name: 'LLAMADA',
        created_format_humans: 'en 4 días',
      },
    ]

    return {
      activityData,
      tableColumns,
      currentPage,
      resolveActivityStatusVariant,
    }
  },
}
</script>

<style>

</style>
