<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isContactHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-contact-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ objetLocal.id ? 'Editar contacto' : 'Agregar contacto' }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              :rules="{ required: true, email: true, uniqueEmail: objetLocal.id }"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="objetLocal.email"
                  :state="getValidationState(validationContext)"
                  trim
                  debounce="1000"
                  @input="existsContactValidate"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <div
                  v-if="existsInfo.contactName"
                  class="mt-1"
                >
                  <b>CONTACTO: {{ existsInfo.contactName }}</b> <br> <b>VENDEDOR: {{ existsInfo.sellerName }}</b>
                </div>
              </b-form-group>
            </validation-provider>

            <template v-if="existsContact || objetLocal.id">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Nombre"
                rules="required"
              >
                <b-form-group
                  label="Nombre"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="objetLocal.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Lastname -->
              <validation-provider
                #default="validationContext"
                name="Apellido"
                rules="required"
              >
                <b-form-group
                  label="Apellido"
                  label-for="lastname"
                >
                  <b-form-input
                    id="lastname"
                    v-model="objetLocal.lastname"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- MobilePhone -->
              <b-form-group
                label="Celular"
                label-for="mobilePhone"
              >
                <cleave
                  id="number"
                  v-model="objetLocal.mobilePhone"
                  class="form-control"
                  :options="options.number"
                  placeholder="___ ___ ___"
                />
              </b-form-group>

              <!-- Email Additional -->
              <b-form-group
                label="Email adicional"
                label-for="emailAdditional"
              >
                <b-form-input
                  id="emailAdditional"
                  v-model="objetLocal.emailAdditional"
                  trim
                />
              </b-form-group>

              <!-- Position in Company -->
              <b-form-group
                label="Cargo"
                label-for="position"
              >
                <b-form-input
                  id="position"
                  v-model="objetLocal.position"
                  trim
                />
              </b-form-group>

              <!-- Business -->
              <validation-provider
                #default="validationContext"
                name="Empresa"
                rules="required"
              >
                <b-form-group
                  label="Empresa"
                  label-for="business"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="objetLocal.businessId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="businessOptions"
                    :clearable="false"
                    label="businessName"
                    :reduce="val => val.id"
                    input-id="business"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Image -->
              <validation-provider
                v-if="objetLocal.id !== undefined"
                #default="validationContext"
                name="Imagen"
              >
                <b-form-group
                  label="Imagen"
                  label-for="image"
                >
                  <b-form-file
                    id="image"
                    ref="refInputElDesktop"
                    v-model="objetLocal.photo"
                    :state="getValidationState(validationContext)"
                    placeholder="Elegir imagen (800x800px)"
                    accept="image/*"
                    @input="renderDesktop"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </template>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-if="existsContact || objetLocal.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ objetLocal.id ? 'Actualizar' : 'Agregar ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import vSelect from 'vue-select'
import contactHandlerSidebar from '@/views/dramox/accounts/contacts/sidebar/contactHandlerSidebar'
import store from '@/store'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Cleave from 'vue-cleave-component'

extend('uniqueEmail', {
  // eslint-disable-next-line consistent-return
  async validate(value, args) {
    const response = await store.dispatch('app-contacts/checkIfContactExists', { email: value, id: args[0] })
    return !response.data.payload.results.exists
  },
  message: 'YA EXISTE ESTE EMAIL REGISTRADO EN EL SISTEMA',
})

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    Cleave,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isContactHandlerSidebarActive',
    event: 'update:is-contact-handler-sidebar-active',
  },
  props: {
    isContactHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    clearData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      options: {
        number: {
          delimiters: [' ', ' '],
          blocks: [3, 3, 3],
          numericOnly: true,
        },
      },
    }
  },
  setup(props, { emit }) {
    const mediumOptions = ref([])
    const businessOptions = ref([])
    const refInputElDesktop = ref(null)

    const {
      objetLocal,
      resetLocal,
      existsContact,
      existsInfo,
      onSubmit,
    } = contactHandlerSidebar(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetLocal(), props.clearData)

    store.dispatch('app-contacts/fetchMediumContacts')
      .then(response => {
        mediumOptions.value = response.data.payload.results
      })

    store.dispatch('app-contacts/fetchBusinessList')
      .then(response => {
        businessOptions.value = response.data.payload.results
      })

    const businessStatusOptions = [
      { label: 'CLIENTE', value: 'CLIENTE' },
      { label: 'POSIBLE CLIENTE', value: 'POSIBLE CLIENTE' },
      { label: 'NO CLIENTE', value: 'NO CLIENTE' },
    ]

    const existsContactValidate = val => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (regex.test(val)) {
        store.dispatch('app-contacts/checkIfContactExists', { email: val, id: objetLocal.id })
          .then(response => {
            existsContact.value = !response.data.payload.results.exists
            existsInfo.value.contactName = response.data.payload.results.contactName
            existsInfo.value.sellerName = response.data.payload.results.sellerName
          })
      }
    }

    const { inputImageRenderer: renderDesktop } = useInputImageRenderer(refInputElDesktop, base64 => {
      // eslint-disable-next-line no-param-reassign

      // showImageDesktop.value = base64
      objetLocal.value.showImage = base64
    })

    return {
      // Add New
      objetLocal,
      onSubmit,

      // Form Validation
      existsContact,
      existsInfo,
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      businessStatusOptions,
      mediumOptions,
      businessOptions,
      renderDesktop,

      refInputElDesktop,
      existsContactValidate,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
</style>
