<template>
  <b-card
    class="text-center"
  >
    <b-overlay
      :show="true"
      class="d-inline-block"
    >
      <b-img
          v-if="backgroundImage"
        height="300"
        width="auto"
        :src="require('@/assets/images/loading/loading-statistics.png')"
        alt="Image 1"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BImg, BOverlay, BCard,
} from 'bootstrap-vue'

export default {
  name: 'LoadingEmptyContent',
  components: {
    BOverlay,
    BImg,
    BCard,
  },
  props: {
    backgroundImage: {
      type: Boolean,
      default: () => true,
    },
  },
}
</script>

<style scoped>

</style>
