<template>
  <b-card
    class="mb-0"
  >
    <b-card-title>Testimonios</b-card-title>
    <b-card-text>
      <b-form-group
        label-for="input-note"
        :state="inputTestimonialState"
        class="mb-1"
        invalid-feedback="Este campo es requerido"
      >

        <b-form-textarea
          id="input-note"
          v-model="testimonialValue.description"
          rows="2"
          :state="inputTestimonialState"
          trim
        />
      </b-form-group>
      <b-button
        variant="primary"
        class="mr-1"
        size="sm"
        @click="saveTestimonial()"
      >
        <span class="text-nowrap">{{ testimonialValue.id ? 'Actualizar' : 'Guardar' }}</span>
      </b-button>
      <b-button
        v-if="testimonialValue.id"
        variant="danger"
        class="mr-1"
        size="sm"
        @click="resetInput"
      >
        <span class="text-nowrap">Cancelar</span>
      </b-button>
    </b-card-text>

    <div class="card-transaction pt-1">
      <div
        v-for="testimonial in testimonials"
        :key="testimonial.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              circle
              size="42"
              variant="light-primary"
            >
              <feather-icon
                size="18"
                icon="UserIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ testimonial.description }}
            </h6>
            <!--            <div class="d-flex align-items-baseline">-->
            <!--              <small>-->
            <!--                <feather-icon-->
            <!--                  icon="ClockIcon"-->
            <!--                  size="16"-->
            <!--                /> {{ note.created_at_humans }}</small>-->
            <!--            </div>-->
          </b-media-body>
        </b-media>
        <div
          v-if="!testimonialValue.id"
          class="font-weight-bolder text-nowrap"
        >
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="editTestimonial(testimonial)"
          />
          <feather-icon
            icon="TrashIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="deleteTestimonial(testimonial)"
          />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle, BButton, BFormTextarea, BFormGroup, BCardText, BMediaBody,
  BMedia, BMediaAside, BAvatar, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BFormTextarea,
    BFormGroup,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BLink,
  },
  props: {
    testimonials: {
      type: Array,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const inputTestimonialState = ref(null)
    const testimonialValue = ref({ description: '' })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const editTestimonial = item => {
      testimonialValue.value = item
      inputTestimonialState.value = true
    }

    const resetInput = () => {
      testimonialValue.value = { description: '' }
      inputTestimonialState.value = false
    }

    const saveTestimonial = () => {
      if (!testimonialValue.value.description) {
        inputTestimonialState.value = false
        return
      }

      store.dispatch('app-contacts/storeTestimonial', {
        body: {
          contactId: props.contact.id,
          description: testimonialValue.value.description,
          testimonialId: testimonialValue.value.id,
        },
      })
        .then(response => {
          const { testimonial } = response.data.payload.results
          const testimonialStore = props.testimonials.find(item => item.id === testimonial.id)
          if (testimonialStore) {
            testimonialStore.descsription = testimonial.description
          } else {
            props.testimonials.unshift(testimonial)
          }
          showNotification({ title: `Testimonio ${testimonialValue.value.id ? 'actualizada' : 'agregada'} con éxito`, icon: 'CheckCircleIcon', variant: 'success' })
          resetInput()
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteTestimonial = item => {
      store.dispatch('app-contacts/deleteTestimonial', {
        id: item.id,
      })
        .then(() => {
          const noteStoreIndex = props.testimonials.findIndex(element => element.id === item.id)
          props.testimonials.splice(noteStoreIndex, 1)
          showNotification({ title: 'Testimonio eliminado', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      resetInput,

      inputTestimonialState,
      testimonialValue,

      deleteTestimonial,
      saveTestimonial,
      editTestimonial,
    }
  },
}
</script>

<style>

</style>
