import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/contacts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContact(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/contact/${id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMediumContacts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/medium/contacts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBusinessList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/business/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServiceInterest() {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/services/interest')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContact(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/contact/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContact(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/contact/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContact(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/contact/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeNote(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/note/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteNote(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/note/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    storeTestimonial(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/testimonial/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTestimonial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/testimonial/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadContact(ctx, { date }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/contacts/download', { params: { date }, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSellers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/sellers/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSellerContact(ctx, { contactId, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/contacts/${contactId}/change-seller`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkIfContactExists(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/contacts/exists', {
            params: body,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
