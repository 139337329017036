<template>
  <b-card title="Historial">
    <app-timeline>
      <app-timeline-item
        v-for="(history,index) in histories"
        :key="index"
      >
        <div class="">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h3>{{ index }}</h3>
          </div>
          <div
            v-for="item in history"
            :key="item.id"
            class=""
          >
            <p v-html="item.action" />
            <p>
              <!--              <span class="align-bottom">hace 1 mes</span>-->
              <!--              <small class="text-muted">{{ item.created_at_humans }}</small>-->
            </p>
          </div>
        </div>
      </app-timeline-item>
      <div
        v-if="Object.keys(histories).length === 0"
        class=""
      >
        <h5>Sin resultados...</h5>
      </div>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    histories: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
