<template>
  <b-card
    class="mb-0"
  >
    <b-card-title>Notas</b-card-title>
    <b-card-text>
      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-0"
          @submit.prevent="handleSubmit(saveNote)"
          @reset.prevent="resetForm"
        >

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Nota"
          >
            <b-form-group
              label-for="note"
              label="Nota"
            >

              <b-form-textarea
                id="note"
                v-model="noteValue.content"
                rows="2"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              size="sm"
            >
              {{ noteValue.noteId ? 'Actualizar' : 'Guardar ' }}
            </b-button>
            <b-button
              v-if="noteValue.noteId"
              variant="danger"
              class="mr-1"
              size="sm"
              @click="resetInput"
            >
              <span class="text-nowrap">Cancelar</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card-text>

    <div class="card-transaction pt-1">
      <div
        v-for="note in notes"
        :key="note.noteId"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              circle
              size="42"
              variant="light-primary"
            >
              <feather-icon
                size="18"
                icon="UserIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ note.content }}
            </h6>
            <div class="d-flex align-items-baseline">
              <small>{{ note.category_name }} - </small>
              <b-link
                class="alert-link font-weight-bold d-block text-nowrap"
              >
                {{ note.client }}
              </b-link>
              - <small>
                <feather-icon
                  icon="ClockIcon"
                  class="ml-1"
                  size="16"
                /> {{ note.created_at_humans }} por {{ note.responsible }}</small>
            </div>
          </b-media-body>
        </b-media>
        <div
          v-if="!noteValue.noteId"
          class="font-weight-bolder text-nowrap"
        >
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="editNote(note)"
          />
          <feather-icon
            icon="TrashIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="deleteNote(note)"
          />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle, BButton, BFormTextarea, BFormGroup, BCardText, BMediaBody,
  BMedia, BMediaAside, BAvatar, BLink, BFormInvalidFeedback, BForm,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, email, numeric, digits,
} from '@validations'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BFormTextarea,
    BFormGroup,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BLink,
    BFormInvalidFeedback,
    BForm,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    notes: {
      type: Array,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      numeric,
      digits,
    }
  },
  setup(props) {
    const inputNoteState = ref(null)
    const data = { content: '' }
    const noteValue = ref(JSON.parse(JSON.stringify(data)))

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const resetData = () => {
      noteValue.value = JSON.parse(JSON.stringify(data))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const editNote = item => {
      noteValue.value = item
    }

    const resetInput = () => {
      noteValue.value = { content: null }
    }

    const saveNote = () => {
      store.dispatch('app-contacts/storeNote', {
        body: {
          contactId: props.contact.id,
          category: 4,
          text: noteValue.value.content,
          noteId: noteValue.value.noteId,
        },
      })
        .then(response => {
          const { note } = response.data.payload.results
          const noteStore = props.notes.find(item => item.noteId === note.noteId)
          if (noteStore) {
            noteStore.content = note.content
          } else {
            props.notes.unshift(note)
          }
          showNotification({ title: `Nota ${noteValue.value.noteId ? 'actualizada' : 'agregada'} con éxito`, icon: 'CheckCircleIcon', variant: 'success' })
          resetForm()
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteNote = item => {
      store.dispatch('app-contacts/deleteNote', {
        id: item.noteId,
      })
        .then(() => {
          const noteStoreIndex = props.notes.findIndex(element => element.noteId === item.noteId)
          props.notes.splice(noteStoreIndex, 1)
          showNotification({ title: 'Note eliminada', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      editNote,
      resetInput,

      inputNoteState,
      noteValue,

      saveNote,
      deleteNote,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
