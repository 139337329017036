<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Guías</h3>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      responsive
      :fields="tableColumns"
      :items="guides"
      class=""
    >

      <!-- Column: Quotation status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveActivityStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(date)="data">
        <span class="text-nowrap">
          {{ data.item.date }} {{ data.item.hour }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'guides-edit', params: { id: data.item.guide_id }})"
          />
          <b-tooltip
            title="Editar"
            placement="right"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-print-icon`"
            icon="PrinterIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="openPDF(data.item)"
          />
          <b-tooltip
            title="Imprimir guía"
            placement="right"
            :target="`invoice-row-${data.item.id}-print-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="cursor-pointer"
            @click="deleteGuide(data.item)"
          />
          <b-tooltip
            title="Eliminar"
            placement="right"
            :target="`invoice-row-${data.item.id}-delete-icon`"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="10"
            :per-page="10"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import guideStoreModule from '@/views/dramox/guides/guideStoreModule'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
  },
  props: {
    guides: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-guides'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, guideStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const currentPage = ref(1)
    const tableColumns = [
      { key: 'nro_guide', label: 'N°de Guia', sortable: false },
      { key: 'business_name', label: 'Emitido', sortable: false },
      { key: 'sale_code', label: 'Cod. Venta', sortable: false },
      { key: 'nro_invoice', label: 'N° Factura', sortable: false },
      { key: 'created_at', label: 'Fecha Creacion', sortable: false },
      { key: 'actions', label: 'Acciones' },
    ]

    const resolveActivityStatusVariant = status => {
      if (status === 'pendiente') return 'warning'
      if (status === 'completado') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    }

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/print/guide/${val.guide_id}`
      window.open(url, '_blanck')
    }

    const deleteGuide = item => {
      store.dispatch('app-guides/deleteGuide', { id: item.guide_id })
        .then(() => {
          showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      tableColumns,
      currentPage,
      resolveActivityStatusVariant,

      openPDF,
      deleteGuide,
    }
  },
}
</script>

<style>

</style>
