<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Ventas</h3>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      responsive
      :fields="tableColumns"
      :items="sales"
      class=""
    >

      <!-- Column: Quotation code -->
      <template #cell(sale_code)="data">
        <b-media vertical-align="center">
          <b-link
              :to="{ name: 'sales-show', params: { id: data.item.id }}"
            class="alert-link font-weight-bold d-block text-nowrap"
          >
            {{ data.item.sale_code }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Quotation status -->
      <template #cell(quotation_status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.quotation_status)}`"
          class="text-capitalize"
        >
          {{ data.item.quotation_status }}
        </b-badge>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.total }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`quotation-row-${data.item.id}-pdf-icon`"
            icon="DownloadIcon"
            class="cursor-pointer"
            @click="openPDF(data.item)"
            size="16"
          />
          <b-tooltip
            placement="right"
            title="Descargar pdf"
            :target="`quotation-row-${data.item.id}-pdf-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="10"
            :per-page="10"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import axios from '@axios'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
  },
  props: {
    sales: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const currentPage = ref(1)
    const tableColumns = [
      { key: 'sale_code', label: 'Código', sortable: false },
      { key: 'total', label: 'Total', sortable: false },
      { key: 'sale_date', label: 'F. Venta', sortable: false },
      { key: 'quotation_status', label: 'Estado', sortable: false },
      { key: 'actions', label: 'Acciones' },
    ]

    const resolveStatusVariant = status => {
      if (status === 'VENTA') return 'success'
      if (status === 'ANULADO') return 'danger'
      if (status === 'CADUCADO') return 'danger'
      if (status === 'PENDIENTE') return 'warning'
      if (status === 'CREADO Y ENVIADO') return 'info'
      return 'primary'
    }

    const salesData = [
      {
        id: 2195,
        quotation_code: 'C27122021-2195',
        sale_code: 'V27122021-2195',
        client: 'Deysi Coronado',
        business_name: 'SODIFER S.A.C',
        total: 802.4,
        observation: null,
        currency: 'pen',
        quotation_status: 'VENTA',
        sale_date: '2021-12-27 14:14:14',
        subtotal: 680,
        igv: '18.00',
        commission: 0,
        guide_id: null,
        currency_symbol: 'S/',
        created_format_humans: 'hace 1 segundo',
      },
    ]

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    return {
      salesData,
      tableColumns,
      currentPage,
      resolveStatusVariant,

      openPDF,
    }
  },
}
</script>

<style>

</style>
