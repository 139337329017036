<template>
  <b-card
    no-body
    class=""
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <!--      <h4 class="mb-0">-->
      <!--        Empresa-->
      <!--      </h4>-->
      <!--      <div class="">-->
      <!--        <b-badge variant="light-info" class="mr-1">-->
      <!--          Servicio interés: {{ business.service_interest }}-->
      <!--        </b-badge>-->
      <!--        <b-badge variant="light-primary">-->
      <!--          {{ business.status_business }}-->
      <!--        </b-badge>-->
      <!--      </div>-->

    </b-card-header>

    <b-card-body class="pb-0">
      <b-row>
        <b-col
          cols="12"
          md="12"
        >

          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th>
                <feather-icon
                  icon="ApertureIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Ruc</span>
              </th>
              <td>
                {{ business.ruc }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="HomeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Razón social</span>
              </th>
              <td class="pb-50">
                {{ business.business_name }}
              </td>
            </tr>

            <tr>
              <th>
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Dirección fiscal</span>
              </th>
              <td>
                {{ business.address_fiscal }} ,{{ business.district }}, {{ business.province }}, {{ business.department }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Fecha de creación del cliente</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contactData.created_at }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Fecha de actualizacion del cliente</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contactData.updated_at }}
              </td>
            </tr>
          </table>

        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BCol,
    BRow,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    contactData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
