<template>
  <div>
    <contact-sidebar
      v-model="isContactHandlerSidebarActive"
      :contact="contactObject"
      :clear-data="() => {}"
      @add-contact="() => {}"
      @update-contact="updateContact"
    />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="contactData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'dashboard'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="contactData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="7"
          lg="7"
          md="7"
        >
          <contact-view-info-card
            :contact-data="contactData"
            :business="businessData"
            @open-edit-sidebar="openSidebar"
            @delete-contact="deleteContact"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="5"
          lg="5"
        >
          <contact-view-business-card
            :business="businessData"
            :contact-data="contactData"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <!--          <contact-view-user-permissions-card />-->
          <!--          <contact-view-quotation-card />-->
          <b-card>
            <b-tabs
              v-if="contactData"
              pills
            >
              <!-- Tab: Quotation -->
              <b-tab active>
                <template #title>
                  <feather-icon
                    icon="ShoppingBagIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Cotizaciones</span>
                </template>
                <contact-view-quotation-card
                  :quotations="quotationsData"
                  :contact-data="contactData"
                />
              </b-tab>

              <!-- Tab: Sale -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ShoppingCartIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Ventas</span>
                </template>
                <contact-view-sale-card :sales="salesData" />
              </b-tab>

              <!-- Tab: Guide -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Guias</span>
                </template>
                <contact-view-guide-card :guides="guidesData" />
              </b-tab>

              <!-- Tab: Activity -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Actividades</span>
                </template>
                <contact-view-activity-card :activities="activitiesData" />
              </b-tab>

            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <contact-view-timeline-history-card :histories="historiesData" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <contact-view-note-card
            :notes="notesData"
            :contact="contactData"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <contact-view-testimonial-card
            :testimonials="testimonialData"
            :contact="contactData"
          />
        </b-col>
      </b-row>

    </template>
    <loading-empty-content
      v-else
      :background-image="false"
    />

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BTab, BTabs, BCard,
} from 'bootstrap-vue'

import ContactViewInfoCard from '@/views/dramox/accounts/contacts/show/ContactViewInfoCard.vue'
import ContactViewBusinessCard from '@/views/dramox/accounts/contacts/show/ContactViewBusinessCard.vue'
import ContactViewTimelineHistoryCard from '@/views/dramox/accounts/contacts/show/ContactViewTimelineHistoryCard.vue'
import contactStoreModule from '@/views/dramox/accounts/contacts/contactStoreModule'
import ContactViewQuotationCard from '@/views/dramox/accounts/contacts/show/ContactViewQuotationCard.vue'
import ContactViewSaleCard from '@/views/dramox/accounts/contacts/show/ContactViewSaleCard.vue'
import ContactViewActivityCard from '@/views/dramox/accounts/contacts/show/ContactViewActivityCard.vue'
import ContactViewNoteCard from '@/views/dramox/accounts/contacts/show/ContactViewNoteCard.vue'
import ContactViewTestimonialCard from '@/views/dramox/accounts/contacts/show/ContactViewTestimonialCard.vue'
import ContactSidebar from '@/views/dramox/accounts/contacts/sidebar/contactSidebar.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoadingEmptyContent from '@core/components/loading/LoadingEmptyContent.vue'
import ContactViewGuideCard from '@/views/dramox/accounts/contacts/show/ContactViewGuideCard.vue'

export default {
  components: {
    ContactViewGuideCard,
    LoadingEmptyContent,
    ContactSidebar,
    ContactViewTestimonialCard,
    ContactViewNoteCard,
    ContactViewActivityCard,
    ContactViewSaleCard,

    ContactViewQuotationCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,
    BCard,

    // Local Components
    ContactViewInfoCard,
    ContactViewBusinessCard,
    ContactViewTimelineHistoryCard,
  },
  setup() {
    const contactData = ref(null)
    const businessData = ref(null)
    const historiesData = ref({ })
    const notesData = ref([])
    const testimonialData = ref([])
    const quotationsData = ref([])
    const salesData = ref([])
    const activitiesData = ref([])
    const guidesData = ref([])
    const isContactHandlerSidebarActive = ref(false)

    const blankBusinessData = {
      name: '',
      lastname: '',
      phone: '',
      extension: '',
      mobilePhone: '',
      email: '',
      emailAdditional: '',
      position: '',
      mediumContact: '',
      businessId: '',
    }

    const contactObject = ref(JSON.parse(JSON.stringify(blankBusinessData)))

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const USER_APP_STORE_MODULE_NAME = 'app-contacts'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, contactStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const fetchContact = () => {
      store.dispatch('app-contacts/fetchContact', { id: router.currentRoute.params.id })
        .then(response => {
          const {
            contact, histories, notes, testimonials, quotations, sales, activities, guides,
          } = response.data.payload.results
          const { photo, ...rest } = contact
          contactData.value = contact
          contactObject.value = rest
          businessData.value = contact.business
          historiesData.value = histories.length === 0 ? {} : histories
          notesData.value = notes
          testimonialData.value = testimonials
          quotationsData.value = quotations
          salesData.value = sales
          activitiesData.value = activities
          guidesData.value = guides
        })
        .catch(error => {
          if (error.response.status === 404) {
            contactData.value = undefined
          }
        })
    }

    onMounted(() => {
      fetchContact()
    })

    const updateContact = val => {
      const formData = new FormData()
      formData.append('name', val.name)
      formData.append('lastname', val.lastname)
      formData.append('phone', val.phone ? val.phone : '')
      formData.append('extension', val.extension ? val.extension : '')
      formData.append('mobilePhone', val.mobilePhone ? val.mobilePhone : '')
      formData.append('email', val.email)
      formData.append('emailAdditional', val.emailAdditional ? val.emailAdditional : '')
      formData.append('position', val.position ? val.position : '')
      if (val.mediumContact) {
        formData.append('mediumContact', val.mediumContact)
      }
      formData.append('businessId', val.businessId)
      formData.append('photo', val.photo)

      store.dispatch('app-contacts/updateContact', { id: val.id, body: formData })
        .then(() => {
          fetchContact()
          showNotification({ title: 'Contacto actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteContact = val => {
      store.dispatch('app-contacts/deleteContact', { id: val.id })
        .then(() => {
          showNotification({ title: 'Contacto eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          router.push({ name: 'contacts' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const openSidebar = () => {
      isContactHandlerSidebarActive.value = true
    }

    return {
      contactData,
      businessData,
      historiesData,
      notesData,
      testimonialData,
      quotationsData,
      salesData,
      activitiesData,
      guidesData,

      isContactHandlerSidebarActive,
      updateContact,
      deleteContact,
      contactObject,
      openSidebar,
    }
  },
}
</script>

<style>

</style>
