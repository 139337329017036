<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Cotizaciones</h3>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="$router.push({ name: 'quotations-create', params: { currency: 'pen'}, query: { contactId: contactData.id} })"
            >

              <span class="text-nowrap">Crear PEN</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="info"
              @click="$router.push({ name: 'quotations-create', params: { currency: 'usd'}, query: { contactId: contactData.id}})"
            >
              <span class="text-nowrap">Crear USD</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      responsive
      :fields="tableColumns"
      :items="quotations"
      class=""
    >

      <!-- Column: Quotation code -->
      <template #cell(quotation_code)="data">
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'quotations-show', params: { id : data.item.id} }"
            class="alert-link font-weight-bold d-block text-nowrap"
          >
            {{ data.item.quotation_code }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Quotation status -->
      <template #cell(quotation_status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.quotation_status)}`"
          class="text-capitalize"
        >
          {{ data.item.quotation_status }}
        </b-badge>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.total }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`quotation-row-${data.item.id}-pdf-icon`"
            icon="DownloadIcon"
            class="cursor-pointer"
            size="16"
            @click="openPDF(data.item)"
          />
          <b-tooltip
            placement="left"
            title="Descargar pdf"
            :target="`quotation-row-${data.item.id}-pdf-icon`"
          />

          <feather-icon
            :id="`quotation-row-${data.item.id}-edit-icon`"
            icon="Edit2Icon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'quotations-edit', params: { id: data.item.id }})"
          />
          <b-tooltip
            placement="left"
            title="Editar"
            :target="`quotation-row-${data.item.id}-edit-icon`"
          />

          <feather-icon
            :id="`quotation-row-${data.item.id}-show-icon`"
            icon="EyeIcon"
            size="16"
            class="mr-1 cursor-pointer"
            @click="$router.push({ name: 'quotations-show', params: { id : data.item.id} })"
          />
          <b-tooltip
            placement="left"
            title="Ver detalle"
            :target="`quotation-row-${data.item.id}-show-icon`"
          />

          <feather-icon
            v-if="data.item.expiration_date"
            :id="`quotation-row-${data.item.id}-sale-icon`"
            icon="ShoppingCartIcon"
            size="16"
            class="mr-1 cursor-pointer"
            @click="changeToSale(data.item)"
          />
          <b-tooltip
            placement="left"
            title="Venta"
            :target="`quotation-row-${data.item.id}-sale-icon`"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="10"
            :per-page="10"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import quotationStoreModule from '@/views/dramox/quotation/quotationStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    BCard, BTable, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
  },
  props: {
    quotations: {
      type: Array,
      required: true,
    },
    contactData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-quotations'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, quotationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const changeToSale = item => {
      store.dispatch('app-quotations/changeQuotationToSale', { id: item.id })
        .then(() => {
          showNotification({
            title: 'Venta agregada con éxito',
            icon: 'CheckCircleIcon',
            variant: 'success',
          })
          window.location.reload()
        })
        .catch(error => {
          showNotification({
            title: 'Oops!, ocurrió un error',
            text: error.response.data.error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    }

    const currentPage = ref(1)
    const tableColumns = [
      {
        key: 'quotation_code',
        label: 'Código',
        sortable: false,
      },
      {
        key: 'total',
        label: 'Total',
        sortable: false,
      },
      {
        key: 'date_created',
        label: 'F. Creación',
        sortable: false,
      },
      {
        key: 'date_updated',
        label: 'F. Actualización',
        sortable: false,
      },
      {
        key: 'expiration_date',
        label: 'F. Vencimiento',
        sortable: false,
      },
      {
        key: 'quotation_status',
        label: 'Estado',
        sortable: false,
      },
      {
        key: 'actions',
        label: 'Acciones',
      },
    ]

    const resolveStatusVariant = status => {
      if (status === 'VENTA') return 'success'
      if (status === 'ANULADO') return 'danger'
      if (status === 'CADUCADO') return 'danger'
      if (status === 'PENDIENTE') return 'warning'
      if (status === 'CREADO Y ENVIADO') return 'info'
      return 'primary'
    }

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    return {
      changeToSale,
      tableColumns,
      currentPage,
      resolveStatusVariant,

      openPDF,
    }
  },
}
</script>

<style>

</style>
